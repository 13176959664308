.PriseList {
  margin-bottom: 4%;
}
.service-block {
  position: relative;
  /* margin-left: 5%; */

  min-height: 83px;

  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: flex-start; */

  text-align: left;
  cursor: pointer;
  border-radius: 10px;
  border-color: #b2664c;
}
.service-name {
  margin-top: 22px;
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
}
.expand-icon {
  margin-right: 10px;
  width: 15px;
  transform-origin: center;
  transition: transform 0.2s ease;
}
.price-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}
.subtitleth {
  text-align: center;
  background-color: #f9fbfc;
}

.price-table th,
.price-table td {
  padding: 8px;
}
.price-table th {
  /* background-color: #F9FBFC; */
  /* font-family: "Lato"; */
  font-weight: 600;
}

.price-table tr {
  border-bottom: 1px solid #ccc;
}
.price-table tr:last-child {
  border-bottom: none;
}
.price-table tr:first-child {
  background-color: #f9fbfc;
}
.price-table th:first-child,
.price-table td:first-child {
  border-top-left-radius: 9px;
}

.price-table th:last-child,
.price-table td:last-child {
  border-top-right-radius: 9px;
}

.service-block .services-content {
  display: block;
  margin-top: 1vh;
  margin-left: 1%;
  margin-right: 1%;
  top: 100%;
  left: 0;
  /* width: 100%; */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.service-block.expanded .services-content {
  display: block;
}
.wrapper-services {
  min-height: auto;
}
.wrapperServices {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 11%;
}
@media (max-width: 1450px) {
  .wrapperServices {
    margin-left: 15%;
  }
}
@media (max-width: 850px) {
  .wrapperServices {
    margin-left: 0;
  }
  .price-table th,
  .price-table td {
    padding: 4px;
  }
  .service-block {
    position: relative;

    min-height: 63px;
  }
  .service-name {
    margin-top: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0em;
  }
  .expand-icon {
    width: 13px;
  }
  .expand-icon img{
    max-width: 13px;
  }
  .price-table {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0em;
  }
}
@media (max-width: 410px) {
  .price-table th,
  .price-table td {
    padding: 1px;
  }
  .service-name {
    margin-top: 16px;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0em;
  }
  .price-table {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
  }
}
/*--------------------------------------------------------------------------------------------------*/
/* .ContactPage{
    display: flex;
} */
.flex-container {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* flex: 1 1 0; */
}
.containerCon {
  margin-bottom: 3%;
  margin-top: 3%;
  display: flex;
  /* border: 2px solid black; */
}
.contact-map-conteiner {
  /* border: 2px solid black; */
  padding: 0 10px;
  margin-bottom: 3%;
}
@media (max-width: 750px) {
  .contact-map-conteiner {
    margin-bottom: 50px;
  }
}
.contact-map-conteiner .main-map-item {
  width: 100%;
  height: 600px;
}
#block1,
#block2 {
  flex: 1;
  margin: 10px;
  border-radius: 22px;
  border: 1px solid #f07e00;
  position: relative;
}
@media (max-width: 1200px) {
  .containerCon {
    flex-wrap: wrap;
  }
  .flex-container {
    flex-basis: 100%;
  }
  #block2 {
    display: none;
  }
}
#block3 {
  flex: 2;
  margin: 10px;
  border-radius: 22px;
  border: 1px solid #f07e00;
}
#block3 .record__form {
  background: rgba(255, 255, 255, 0.2);
}
#block3 .record__title {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 17px;
}
#block3 .record__form.submit {
  width: auto;
  height: 100%;
  align-items: center;
}
#block3 .submit__title {
  color: #000;
  font-size: 24px;
}
@media (max-width: 450px) {
  #block3 .submit__title {
    color: #000;
    font-size: 18px;
  }
}
#block3 .record__subtitle {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
#block3 .record__inputs {
  color: #363636;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
#block3 .record-input {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}
#block3 .record__inputs .record__input-group > input:focus {
  border-bottom: 2px solid rgba(0, 0, 0, 1);
}
#block3 .record__button-submit {
  background: linear-gradient(154deg, #f07e00 0%, #f46353 100%);
  color: #fff;
}
#block3 .record__checbox-agreement-custom {
  /* border: 1px solid #fff5ea; */
  border: 1px solid #f07e00;
  background: #fff;
}
#block3 .record__checbox-agreement-custom::before {
  background-image: url("../img/main-page/checkboxsvg2.svg");
}
#block3 .record__checkbox {
  color: #444;
}
#block4 {
  flex: 1;
  margin: 10px;
  border-radius: 22px;
  /* border: 1px solid #f07e00; */
  display: none;
}
@media (max-width: 1200px) {
  #block4 {
    display: flex;
  }
  .flex-container {
    min-width: auto;
  }
}
.block {
  display: flex;
  flex-direction: column;
}

.text {
  margin: 10px;
}

.text span {
  text-decoration: underline;
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

.text > p {
  margin: 10px;
  flex-grow: 1;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.logo_vk {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.photo {
  background-size: cover;
}
@media (max-width: 850px) {
  .text > p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 400px) {
  .text > p {
    font-size: 14px;
  }
}
/*--------------------------------------------------------------------------------------------------*/
.PrivacyPolicy {
}
.ContentPrivacy {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 22px;
  border: 1px solid #f07e00;
}
.ContentPrivacy .subtitle {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}
.ContentPrivacy .text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.ContentPrivacy .block_info {
  margin-top: 3%;
}
.ContentPrivacy .block_info:first-child {
  margin-top: 0%;
}
.ContentPrivacy .table {
  border: solid 1px #f17f01;
  border-radius: 10px;
  margin-top: 2%;
  margin-left: 2%;
}
.ContentPrivacy td {
  padding: 10px;
}
.table tr {
  border-bottom: 1px solid #f17f01;
}

@media (max-width: 850px) {
  .ContentPrivacy .subtitle {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
  }
  .ContentPrivacy .text {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
  }
  .ContentPrivacy .table {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
  }
}
@media (max-width: 550px) {
  .ContentPrivacy .subtitle {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
  }
  .ContentPrivacy .text {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }
  .ContentPrivacy .table {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }
}

/*--------------------------------------------------------------------------------------------------*/
.ServicePage {
  margin-bottom: 4%;
}
.ServiceBlock {
  margin-top: 3vh;
  display: flex;
}
.ServiceBlock {
  margin-top: 3vh;
  display: flex;
}
.LeftSection {
  flex: 1;
  padding: 10px;
  max-width: 200px;
}

.CenterSection {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.CenterBlock {
  flex: 1;
  border: 1px solid #f17f01;
  padding: 25px;
  border-radius: 22px;
  margin-right: 20%;
}
.CenterBlock > p {
  margin-top: 1vh;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.CenterBlock > p:first-child {
  margin-top: 0;
}
.CenterBlock > ul {
  padding-left: 15px;
  padding-top: 10px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.CenterFill {
  background-color: #f07e00;
}
.CenterFill > p {
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
}
.LeftSection > .LeftTitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
}
.LeftSection > .LeftSubTitle {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.tableWrapperService {
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 3%;
}
.service-table {
  /*  */
  align-self: flex-end;
  margin-right: 0;
  width: 100%;
  font-family: "Lato", sans-serif;
  color: #6a6a65;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  border-collapse: collapse;
}
.service-table th,
.service-table td {
  padding: 8px;
}
.service-table tr {
  border-bottom: 1px solid #ccc !important;
}

.service-table tr:first-child {
  background-color: #f9fbfc;
}
.service-table td:first-child {
  padding-left: 25px;
}
.service-table tr:first-child td:first-child {
  border-top-left-radius: 23px;
}
.service-table tr:first-child td:last-child {
  border-top-right-radius: 23px;
}
.service-table tr:last-child {
  border-bottom: 0px solid #ccc !important;
}

@media (max-width: 850px) {
  .ServiceBlock {
    flex-direction: column;
  }
  .CenterBlock {
    margin-right: 0%;
  }
}
@media (max-width: 550px) {
  .CenterBlock > p {
    font-size: 11px;
    line-height: 18px;
  }
  .CenterBlock > ul {
    padding-left: 11px;
    padding-top: 7px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  }
  .CenterFill > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }
  .service-table {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

/* 
__________________________________________________________________________________________ */
.Service {
  margin-bottom: 3%;
}

.wrapperServiceblock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2%;
}

.block_service {
  width: calc(24% - 20px);
  min-height: 250px;
  /* background-color: #fafafa; */
  border: 1px solid #f07e00;
  border-radius: 17px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
}
.blockFake {
  background-color: #fff;
  border: 0;
}

.block_service .photo {
  /* width: 100%;
     height: 100%;
    object-fit: cover; */
  border-radius: 17px;
}
.block_service .photo2{
    min-width: 100%;
}
.blockdisplay {
  display: block;
  border: none;
}
@media (max-width: 850px) {
  .block_service {
    flex-basis: 45%;
    min-height: 250px;
  }
  .blockFake {
    display: none;
  }
}
@media (max-width: 550px) {
  .block_service {
    min-height: 150px;
  }
  .block_service .advantages-cards__title {
    padding: 9px 10px 5px 10px;
  }
  .block_service .advantages-cards__desctription {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.aboutBold {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.submit_payment {
  border-radius: 40px;
  background: linear-gradient(154deg, #f07e00 0%, #f46353 100%);
  color: #fff;
  padding: 13px 28px;
  transition: background 0.3s ease, transform 0.3s ease;
  display: block; /* Для центрирования через margin */
  margin: 20px auto; /* Центрирование */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submit_payment:hover {
  background: linear-gradient(154deg, #f46353 0%, #f07e00 100%);
  transform: scale(1.05); /* Легкое увеличение кнопки */
}
.highlighted-link {
  color: #f07e00; /* Оранжевый цвет */
  text-decoration: none;
  font-weight: bold;
}

.highlighted-link:hover {
  text-decoration: underline; /* Подчеркивание при наведении */
}
.blockpayment{
  width: 60%;
  margin-left: 20%;
}
@media (max-width: 850px) {
  .blockpayment{
    width: 100%;
    margin-left: 0%;
  }
}

.for_price{
  margin-right: 0;
  margin-bottom: 3%;
}